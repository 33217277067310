.hamburger-menu{
    display: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;

    height: 0%;
    width: 0%;

    background-color: var(--overlay-color);
    opacity: 0.2;
}

.overlay.show{
    height: 100%;
    width: 100%;
}

.drawer {
    position: fixed;
    top: 0;
    left: 0;

    background-color: var(--background-color);
    height: 0px;
    width: 0%;
    border-radius: 0 0 10px 10px;
    opacity: 1;

    transition: height 0.5s;
}

.drawer.show{
    width: 100%;
    height: 300px
}





@media only screen and (max-width: 450px) {
    .hamburger-menu{
        position: absolute;

        top: 10px;
        right: 10px;

        height: 40px;
        width: 40px;

        display: initial;
    }
}